import { Module, ActionTree, MutationTree, GetterTree, Store } from 'vuex';

import { RootState } from '@/store/types';
import { registerSubModule } from '@/utils/vueUtils';

import {
  GetFilterCounts,
  GetPage,
  GridPage,
  FilterCounts,
  ApiPaging,
  PagingResult,
} from './FilterableGridModule.api';
import {
  FilterableGridViewModel,
  FilterOption,
  uncheckAll,
  getFilterValues,
  setFilterCount,
  IGridCard,
  FilterItem,
  getFilterCountsByCards,
  filterCards,
} from './FilterableGridModule.types';
import { RootGetters } from '@/store/getters';
import { sort } from '@/utils/commonUtils';
import CtaLinkItem from '@/common/interfaces/CtaLinkItem';

export interface FilterableGridModuleState extends FilterableGridViewModel {
  ResetCardsState: boolean;
  CurrentSorting: string;
  FEAllCards?: IGridCard[];
  IsLoading: boolean;
  HeaderButtonHidden?: boolean;
}

interface UpdatePageParams {
  fullReplace: boolean; // false only for paging actions
  paging: ApiPaging;
  isInitial?: boolean;
}

interface OuterFilterParams {
  filter: FilterItem;
  filterName: string;
  value: number | string | boolean;
}

export const INIT_GRID = 'INIT_GRID';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_OUTER_FILTER = 'UPDATE_OUTER_FILTER';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_SORTING = 'UPDATE_SORTING';
// export const CHANGE_PAGE = 'CHANGE_PAGE';
export const UPDATE_FILTER_COUNTS = 'UPDATE_FILTER_COUNTS';
export const LOAD_MORE = 'LOAD_MORE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
const actions: ActionTree<FilterableGridModuleState, RootState> = {
  [INIT_GRID]({ dispatch, getters, state }) {
    const gettersT: ModuleGetters = getters;

    if (!state.HideFilters) dispatch(UPDATE_FILTER_COUNTS);

    const params: UpdatePageParams = {
      fullReplace: true,
      isInitial: true,
      paging: {
        PageSize: gettersT.GET_PAGE_SIZE,
        Start: 0,
      },
    };
    dispatch(UPDATE_PAGE, params);
  },
  [UPDATE_FILTER]({ commit, dispatch }, option: FilterOption) {
    commit(RESET_CARD_STATE);
    commit(TOGGLE_FILTER, option);
    dispatch(UPDATE_FILTER_COUNTS);
  },
  [UPDATE_OUTER_FILTER]({ state, commit, dispatch }, params: OuterFilterParams) {
    if (params.filterName) {
      const filter = state.Filters.find((f) => f.Name === params.filterName);
      if (filter) {
        const option = filter.Options[0];
        const value = params.value;

        commit(UPDATE_FILTER_VALUE, { option, value });
        dispatch(APPLY_FILTERS);
      }
    }

    if (params.filter && params.filter.IsDropdown) {
      // Toggle to uncheck previous and check current value
      params.filter.Options.filter(
        (o) => (o.Checked && o.Value !== params.value) || (!o.Checked && o.Value === params.value),
      ).forEach((o) => commit(TOGGLE_FILTER, o));
      dispatch(APPLY_FILTERS);
    }
    if (params.filter && params.filter.IsSingleCheckbox) {
      commit(TOGGLE_FILTER, params.filter.Options[0]);
      dispatch(UPDATE_FILTER_COUNTS);
      dispatch(APPLY_FILTERS);
    }
  },
  [CLEAR_FILTERS]({ commit, dispatch, getters }, updatePage: boolean) {
    const gettersT: ModuleGetters = getters;

    const filtersToShow = gettersT.GET_FILTERS_TO_SHOW?.filter(
      (f) => !f.IsDropdown && !f.IsSingleCheckbox,
    );

    commit(M_CLEAR_FILTERS, filtersToShow);

    dispatch(UPDATE_FILTER_COUNTS);
    if (updatePage) {
      dispatch(APPLY_FILTERS);
    }
  },
  [APPLY_FILTERS]({ dispatch, getters }) {
    const gettersT: ModuleGetters = getters;

    const params: UpdatePageParams = {
      fullReplace: true,
      paging: {
        PageSize: gettersT.GET_PAGE_SIZE,
        Start: 0,
      },
    };
    dispatch(UPDATE_PAGE, params);
  },
  [UPDATE_SORTING]({ commit, dispatch, state, getters }, sortingValue: string) {
    const gettersT: ModuleGetters = getters;

    commit(M_UPDATE_SORTING, sortingValue);

    const params: UpdatePageParams = {
      fullReplace: true,
      paging: {
        PageSize: gettersT.PAGE_COUNT_FOR_UPDATE,
        Start: 0,
      },
    };

    commit(RESET_CARD_STATE);

    dispatch(UPDATE_PAGE, params);
  },
  [LOAD_MORE]({ state, commit, dispatch, getters }) {
    const gettersT: ModuleGetters = getters;
    const params: UpdatePageParams = {
      fullReplace: false,
      paging: {
        PageSize: gettersT.GET_PAGE_SIZE,
        Start: gettersT.CURRENT_PAGE_COUNT,
      },
    };
    dispatch(UPDATE_PAGE, params);
  },
  // [CHANGE_PAGE]({ dispatch }) {
  //   const params: UpdatePageParams = { fullReplace: false };
  //   dispatch(UPDATE_PAGE, params);
  // },

  // API actions
  async [UPDATE_FILTER_COUNTS]({ state, commit, getters }) {
    const gettersT = getters as ModuleGetters;
    const { Endpoint, Filters } = state;

    const filtersToShow = gettersT.GET_FILTERS_TO_SHOW?.filter((f) => !f.IsDropdown);

    if (filtersToShow?.length && Filters) {
      let counts: FilterCounts = null;
      if (gettersT.IS_FE) {
        const cards = gettersT.GET_FILTERED_CARDS;

        counts = {
          FilterCounts: getFilterCountsByCards(state.FEAllCards, Filters),
          TotalCount: cards.length,
        };

        // console.log('counts', cards.length, counts);
      } else {
        counts = await GetFilterCounts(Endpoint, getFilterValues(Filters));
      }

      commit(M_UPDATE_FILTER_COUNTS, counts);
    }
  },
  async [UPDATE_PAGE]({ state, commit, getters }, params: UpdatePageParams) {
    try {
      commit(SET_LOADING_STATE, true);
      const gettersT = getters as ModuleGetters;

      let page: GridPage = null;
      if (gettersT.IS_FE) {
        const cards = getters.GET_FILTERED_CARDS;
        const showCount = params.paging.Start + params.paging.PageSize;
        const pagedCards = params.paging.PageSize === -1 ? cards : cards.slice(0, showCount);

        page = {
          Cards: pagedCards,
          Paging: {
            Total: cards.length,
          },
        };

        commit(M_UPDATE_FE_PAGE, page);
      } else {
        const page = await GetPage(
          state.Endpoint,
          {
            Paging: params.paging, // Depends on current breakpoint
            Filters: state.Filters ? getFilterValues(state.Filters) : {},
            Sorting: state.CurrentSorting,
          },
          params.isInitial ? { showLoading: false } : undefined,
        );

        page.IsFullReplace = params.fullReplace;

        if (!state.EmptyGridMessage && page.Paging.Total == 0 && state.Filters)
          commit(SET_EMPTY_GRID_MESSAGE, "No results. Try adjusting your search by removing filters or changing your dates");

        commit(M_UPDATE_PAGE, page);
      }
    } catch (e) {
      //console.log(e);
    } finally {
      commit(SET_LOADING_STATE, false);
    }
  },
};

export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const CHANGE_FILTERS = 'APPLY_FILTERS';
export const M_CLEAR_FILTERS = 'CLEAR_FILTERS';
export const M_UPDATE_SORTING = 'UPDATE_SORTING';
export const M_UPDATE_FE_PAGE = 'M_UPDATE_FE_PAGE';
export const M_UPDATE_PAGE = 'M_UPDATE_PAGE';
export const M_UPDATE_FILTER_COUNTS = 'M_UPDATE_FILTER_COUNTS';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_EMPTY_GRID_MESSAGE = 'SET_EMPTY_GRID_MESSAGE';
export const UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE';
export const M_UPDATE_HEADER_BUTTON = 'M_UPDATE_HEADER_BUTTON';
export const M_HIDE_SHOW_HEADER_BUTTON = 'M_HIDE_SHOW_HEADER_BUTTON';
export const RESET_CARD_STATE = 'RESET_CARD_STATE';
export const M_UPDATE_MASONRY = 'M_UPDATE_MASONRY';

const mutations: MutationTree<FilterableGridModuleState> = {
  [M_UPDATE_MASONRY](state, payload) {
    state.Masonry.TopTitle = payload.TopTitle;
    state.Masonry.MainTitle = payload.MainTitle;
  },
  [RESET_CARD_STATE](state) {
    state.ResetCardsState = !state.ResetCardsState;
  },
  [SET_LOADING_STATE](state, payload) {
    state.IsLoading = payload;
  },
  [SET_EMPTY_GRID_MESSAGE](state, payload) {
    state.EmptyGridMessage = payload;
  },
  [TOGGLE_FILTER](state, option: FilterOption) {
    // eslint-disable-next-line no-param-reassign
    option.Checked = !option.Checked;
  },
  [UPDATE_FILTER_VALUE](state, { option, value }) {
    option.Value = value;
  },
  [M_CLEAR_FILTERS](state, filters: FilterItem[]) {
    filters.forEach(uncheckAll);
  },
  [M_UPDATE_SORTING](state, sortingValue: string) {
    state.CurrentSorting = sortingValue;
  },
  [M_UPDATE_FE_PAGE](state, page: GridPage) {
    if (state.Paging) {
      state.Paging.Total = page.Paging.Total;
    }

    state.Cards = page.Cards;
  },
  [M_UPDATE_PAGE](state, page: GridPage) {
    state.Paging.Total = page.Paging.Total;
    if (page.IsFullReplace) {
      state.Cards = page.Cards;
    } else {
      page.Cards.forEach((c) => state.Cards.push(c));
    }
  },
  [M_UPDATE_FILTER_COUNTS](state, counts: FilterCounts) {
    // state.Paging.Total = counts.TotalCount;

    Object.keys(counts.FilterCounts).forEach((filterName) => {
      const filter = state.Filters?.find((f) => f.Name === filterName);
      const filterCounts = counts.FilterCounts[filterName];

      if (filter && filterCounts) {
        setFilterCount(filter, filterCounts);
      }
    });
  },
  [M_UPDATE_HEADER_BUTTON](state, item: CtaLinkItem) {
    state.HeaderCta = Object.assign({}, state.HeaderCta, item);
  },
  [M_HIDE_SHOW_HEADER_BUTTON](state, isHidden: boolean) {
    state.HeaderButtonHidden = !isHidden;
  },
};

export const GET_PAGE_SIZE = 'GET_PAGE_SIZE';
export const CURRENT_PAGE_COUNT = 'CURRENT_PAGE_COUNT';
export const PAGE_COUNT_FOR_UPDATE = 'PAGE_COUNT_FOR_UPDATE';
export const GET_FILTERS_TO_SHOW = 'GET_FILTERS_TO_SHOW';

export const GET_FILTERED_CARDS = 'GET_FILTERED_CARDS';
export const PAGE_CARDS = 'PAGE_CARDS';
export const IS_FE = 'IS_FE';

interface ModuleGetters {
  GET_PAGE_SIZE: number;
  CURRENT_PAGE_COUNT: number;
  PAGE_COUNT_FOR_UPDATE: number;
  GET_FILTERS_TO_SHOW: FilterItem[];
  GET_FILTERED_CARDS: IGridCard[];
  PAGE_CARDS: IGridCard[];
  IS_FE: boolean;
}

const getters: GetterTree<FilterableGridModuleState, RootState> = {
  [GET_PAGE_SIZE](state, getters, rootState, rootGetters: RootGetters) {
    let pageSize = state.Paging?.PageSize;
    if (rootGetters.isInBreakpoint('xs')) pageSize = state.Paging?.PageSizeSmall;
    if (rootGetters.isInBreakpoint('md')) pageSize = state.Paging?.PageSizeMedium;
    pageSize = pageSize || -1;

    return pageSize;
  },
  [CURRENT_PAGE_COUNT](state) {
    return state.Cards.length;
  },
  [PAGE_COUNT_FOR_UPDATE](state, getters: ModuleGetters) {
    const gettersT = getters as ModuleGetters;

    return Math.max(gettersT.CURRENT_PAGE_COUNT, gettersT.GET_PAGE_SIZE);
  },
  [GET_FILTERS_TO_SHOW](state) {
    // filter single option filter (edge case for sub-pages)
    return state.Filters?.filter(
      (f) => !(f.Options && !f.IsSingleCheckbox && f.Options.length === 1 && f.Options[0].Checked),
    );
  },
  [GET_FILTERED_CARDS](state) {
    // Only FE
    let filtered = [...state.FEAllCards];
    const sorting = state.Sorting?.find((s) => s.Value === state.CurrentSorting);

    if (state.Filters?.length) {
      filtered = filterCards(filtered, state.Filters);
    }

    if (sorting) {
      sort(filtered, (c) => c[sorting.Data.Field], sorting.Data.Direction, true);
    }

    return filtered;
  },
  [PAGE_CARDS](state) {
    return state.Cards;
  },
  [IS_FE](state) {
    return !state.Endpoint;
  },
};

const registerModule = registerSubModule<FilterableGridModuleState, FilterableGridViewModel>(
  (data: FilterableGridViewModel) => {
    let firstSorting: string = '';
    if (data.Sorting && data.Sorting[0]) firstSorting = data.Sorting[0].Value.toString();

    const state: FilterableGridModuleState = {
      ...data,
      CurrentSorting: data.CurrentSorting || firstSorting,
      IsLoading: false,
      ResetCardsState: false,
    };

    if (!data.Endpoint) {
      state.FEAllCards = state.Cards;
      state.Cards = [];
    }

    return {
      namespaced: true,
      state,
      actions,
      mutations,
      getters,
    } as Module<FilterableGridModuleState, RootState>;
  },
  'FilterableGridModule',
);

export default async (store: Store<RootState>, data: FilterableGridViewModel) =>
  registerModule(store, data);
