import { mixins } from 'vue-class-component';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import StoreModuleMixin from '@/mixins/StoreModuleMixin';
import OverlayMixin from '@/mixins/OverlayMixin';

import { FilterItem, FilterOption, getCheckedCount, getCheckedFilters } from './../../FilterableGridModule.types';

import FilterComponent from './FilterItem/FilterItem.vue';
import FilterIcon from '@/assets/icons/filter.svg?inline';
import Close from '@/assets/icons/cross.svg?inline';

import { UPDATE_FILTER, CLEAR_FILTERS, APPLY_FILTERS } from './../../FilterableGridModule.store';
import FocusTrapDirective from '@/directives/FocusTrapDirective';
import { SegmentElement } from '@/plugins/utm/SegmentElement';
import { PAGE_META, PageMeta } from '@/store/modules/metadataModule';
import { Getter } from 'vuex-class';
import ItineraryCard from '@/common/interfaces/modules/ItineraryCard';
import { cloneSimpleObject } from '@/utils/commonUtils';

@Component({
  components: { FilterComponent, FilterIcon, Close },
  directives: {
    'trap-focus': FocusTrapDirective,
  },
})
export default class FilteringComponent extends mixins(StoreModuleMixin, OverlayMixin) {
  @Prop({ type: Array as () => FilterItem[], default: [] })
  readonly filters!: FilterItem[];

  selectedFilters: FilterItem[] = [];

  isOpen = false;
  wasOpened = false;
  applyFiltersClicked = false;

  @Ref('gridContainer') readonly gridContainer: HTMLDivElement;

  @Watch('isOpen')

  function(isOpen: boolean) {
    this.$nextTick(() => {
      this.openCloseOverlay(isOpen, this.gridContainer);
    })
    if (this.isOpen && this.wasOpened && !this.checkedFiltersLength) {
      this.wasOpened = false;
    }
  }

  created() {
    this.setSelectedFilters()
  }

  @Getter(PAGE_META) PageMeta!: PageMeta;

  get checkedFiltersLength() {
    return getCheckedCount(this.filters);
  }

  get selectedFiltersLength() {
    return getCheckedCount(this.selectedFilters)
  }

  setSelectedFilters() {
    this.selectedFilters = cloneSimpleObject(this.filters);
  }

  get isInineraryFilter() {
    return this.state?.Endpoint?.includes('Itinerary')
  }

  get Cards() {
    return this.state?.Cards as ItineraryCard[];
  }

  get sorts() {
    const type = this.state.Sorting?.find(s => s.Value === this.state.CurrentSorting)?.Title;
    const value = this.state.CurrentSorting;
    return [{ type, value }]
  }

  filterChanged: (option: FilterOption) => void = this.getAction(UPDATE_FILTER);
  applyClearFilters: (updatePage?: boolean) => void = this.getAction(CLEAR_FILTERS);
  applyFiltersAction: () => void = this.getAction(APPLY_FILTERS);

  clearFilters(updatePage?: boolean) {
    this.applyClearFilters(updatePage)
    this.setSelectedFilters()
  }

  applyFilters() {
    this.applyFiltersAction();
    this.applyFiltersClicked = true;
    this.isOpen = false;
    this.wasOpened = true
  }

  @Watch('state.IsLoading')
  IsLoadingChanged(newVal: boolean): void {
    if (!newVal && this.applyFiltersClicked) {
      this.setSelectedFilters()
      this.applyFiltersClicked = false;
      const filters = getCheckedFilters(this.filters);
      if (filters.length) {
        if (!this.isInineraryFilter) {
          SegmentElement.sendEvent('filtersApplied', {filters})
        } else {
          const list_id = this.PageMeta.ContextMeta.Destination ? JSON.parse(this.PageMeta.ContextMeta.Destination).Id.toString() : null;
          const products = this.Cards.map(card => ({ 
            brand: 'Lindblad Expeditions',
            categories: card.Locations.map(category => ({ category })),
            image_url: card.Image.Url,
            name: card.Title,
            ...(card.SpecialOffers.length && { offers: card.SpecialOffers.map(offer => ({ offer_name: offer.Question })) }),
            product_id: card.ProductType
          }))
          SegmentElement.sendEvent('productListFiltered', {
            nonInteraction: 1,
            filters,
            category: this.PageMeta.Analytics.categories ? this.PageMeta.Analytics.categories[0].category : null,
            list_id,
            products,
            sorts: this.sorts,
          })
        }
      }
    }
  }
}
