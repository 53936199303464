import { Component, Prop } from 'vue-property-decorator';
import { FilterItem, FilterOption, getCheckedOptions } from './../../../FilterableGridModule.types';

import { SwiperOptions } from '@/utils/swiperUtils';

import Tick from '@/assets/icons/tick-alt.svg?inline';

import ArrowIcon from '@/assets/icons/chevron.svg?inline';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';

@Component({
  components: {
    ArrowIcon,
    Tick,
  },
})
export default class FilterItemComponent extends mixins(SwiperMixin) {
  @Prop({ type: Object as () => FilterItem })
  readonly filter!: FilterItem;

  currentTabIndex: number = 0;

  clickedItem = '';
  //filterValues = this.filter.Options.map(filterValues => filterValues.Value);

  filterChanged(option: FilterOption) {
    this.$emit('filterChanged', option);
  }

  isCollapse: boolean = false;

  toggleCollapse(): void {
    this.isCollapse = !this.isCollapse;
  }

  setActiveTab(tabId: number) {
    this.currentTabIndex = tabId;
  }

  get currentTab() {
    if (this.filter.Groups) return this.filter.Groups[this.currentTabIndex];
    return null;
  }

  get dateLength() {
    if (this.filter.Groups) return this.filter.Groups.length <= 3;
  }

  get yearLength() {
    if (this.filter.Groups) return this.filter.Groups.length;
  }

  get isSingleFilterOption() {
    if (this.filter.Name !== 'Dates') return this.filter.Options.length <= 1;
  }

  get checkedItems() {
    if (this.filter.Groups) {
      return this.filter.Groups.map((g) =>
        g.Options.filter((o) => o.Checked)
          .map((o) => `${g.Header} ${o.Text}`)
          .join("\u00A0/\u00A0"),
      )
        .filter((g) => g)
        .join("\u00A0/\u00A0");
    }

    return getCheckedOptions(this.filter)
      .map((o) => o.Text)
      .join("\u00A0/\u00A0");
  }

  get isAnyOptionsChecked() {
    return getCheckedOptions(this.filter).length > 0;
  }

  get swiperOption(): SwiperOptions {
    return {
      slidesPerView: 3,
      centeredSlides: false,
      slidesOffsetAfter: 20,
      slidesOffsetBefore: 20,
      watchOverflow: true,
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: `#c-${this.uuid}-next`,
        prevEl: `#c-${this.uuid}-prev`,
      },
    };
  }
}
